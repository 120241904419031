import React from "react";
import Menu from "../components/menu/Menu";
import Footer from "../components/footer/Footer";
import Short from "../components/short/Short";

const AboutPage = () => {
    return <>
        <Menu />
        <Short activeRoute={'За Нас'} />
        <section className="about py-lg-4 py-md-3 py-sm-3 py-3" id="about">
            <div className="container py-lg-5 py-md-4 py-sm-4 py-3">
                <div className="row">
                    <div className="col-lg-8 col-md-6 text-right about-two-grids">
                        <h3 className="title  mb-md-4 mb-sm-3 mb-3">Нашият <span>Екип</span></h3>
                        <div className="about-para-txt">
                            <p>В сърцето на La Aura е екип от сертифицирани професионалисти, които също като вас са ценители на перфектната белота. Те разбират, че ярката, сияйна усмивка е повече от просто козметично подобрение – тя е отражение на самоувереност и жизненост.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 about-imgs-txt">
                        <img src="assets/images/smile1.jpg" alt="news image" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
        <section className="stats-count py-lg-4 py-md-3 py-sm-3 py-3">
            <div className="container-fluid py-lg-5 py-md-4 py-sm-4 py-3">
                <div className="row text-center">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 about-us-topic-info mb-4 py-2">
                        <i className="fa fa-id-card ui default"></i>
                        <h5>ВИСОКО КВАЛИФИЦИРАН ПЕРСОНАЛ</h5>
                        <h6 className="pt-2">Нашите специалисти са истински майстори на занаята си</h6>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 about-us-topic-info mb-2 py-2">
                        <i class="fa fa-heartbeat ui default"></i>
                        <h5>ИНОВАТИВНИ МЕТОДИ НА ИЗБЕЛВАНЕ</h5>
                        <h6 className="pt-2">услуга с първокласно качество</h6>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 about-us-topic-info py-2">
                        <i class="fa fa-money ui default"></i>
                        <h5>СЪОТНОШЕНИЕ ЦЕНА-КАЧЕСТВО</h5>
                        <h6 className="pt-2">висококачествено обслужване на достъпни цени</h6>
                    </div>
                </div>
            </div>
        </section>
        <section className="advertise-count">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 adv-text">
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 counter-number">
                        <div className="fashion-collet-txt">
                            {/* <h6 className="text-center mt-lg-4 mt-3">до 9 Нюанса по</h6> */}
                            <h6 className="text-center">Нека усмивката ти </h6>
                            <h5 className="text-center">отново да заблести </h5>
                           

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default AboutPage;