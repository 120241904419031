import React from "react";
import './price-list.css';
import { Link, useNavigate } from "react-router-dom";

function PriceList() {

    const navigate = useNavigate();
    const requestPlanHandler = (event) => {
        event.preventDefault();

        navigate('/contact')
    }
    return (
        <div className="pricingTable">
            <h2 className="pricingTable-title">Цени</h2>
            <h3 className="pricingTable-subtitle">Еднократни посещения.</h3>

            <ul className="pricingTable-firstTable">
                <li className="pricingTable-firstTable_table">
                    <h1 className="pricingTable-firstTable_table__header">Bleach 30</h1>
                    <p className="pricingTable-firstTable_table__pricing"><span>90</span><span>лв.</span></p>
                    <ul className="pricingTable-firstTable_table__options">
                        <li>Eдиничен Сеaнс - 30 минити </li>
                        <li className="not-before"></li>
                    </ul>
                    <button className="pricingTable-firstTable_table__getstart" onClick={(event) => requestPlanHandler(event)}> Заяви Сега</button>
                </li><li className="pricingTable-firstTable_table">
                    <h1 className="pricingTable-firstTable_table__header">Bleach 60</h1>
                    <p className="pricingTable-firstTable_table__pricing"><span>160</span><span>лв.</span></p>
                    <ul className="pricingTable-firstTable_table__options">
                        <li>Двоен Сеанс - 60 минути</li>
                        <li className="not-before"></li>
                    </ul>
                    <button className="pricingTable-firstTable_table__getstart" onClick={(event) => requestPlanHandler(event)}>Заяви Сега</button>
                </li><li className="pricingTable-firstTable_table">
                    <h1 className="pricingTable-firstTable_table__header">Bleach Diamond</h1>
                    <p className="pricingTable-firstTable_table__pricing"><span>200</span><span>лв.</span></p>
                    <ul className="pricingTable-firstTable_table__options">
                        <li>Троен Сеанс - 90 минути</li>
                        <li className="not-before"> </li>
                    </ul>
                    <button className="pricingTable-firstTable_table__getstart" onClick={(event) => requestPlanHandler(event)}>Заяви Сега</button>
                </li>

            </ul>
            <h3 className="pricingTable-subtitle" style={{ marginTop: '60px' }}>Едногодишен пакет.</h3>
            <ul className="pricingTable-firstTable">
                <li className="pricingTable-firstTable_table table-plat">
                    <h1 className="pricingTable-firstTable_table__header">Hollywood Smile Package</h1>
                    <p className="pricingTable-firstTable_table__pricing"><span>750</span><span>лв./год.</span></p>
                    <ul className="pricingTable-firstTable_table__options">
                        <li>Eжемесечно избелване </li>
                        <li>Полиране на зъби </li>
                    </ul>
                    <button className="pricingTable-firstTable_table__getstart" onClick={(event) => requestPlanHandler(event)}>Заяви Сега</button>
                </li>
            </ul>


            <ul className="pricingTable-secondTable">

            </ul>
        </div>
    );
}

export default PriceList;