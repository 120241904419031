import React from "react";
import './footer.css';
import { Link, useNavigate } from "react-router-dom";
function Footer() {

    const navigate = useNavigate();

    const scrollToAnchor = (event, page, section) => {
        event.preventDefault();
        navigate(page, { state: { section } });
    }

    return <footer className="py-lg-4 py-md-3 py-sm-3 py-3">
        <section className="contact-section" id="contacts">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5">
                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <svg style={{ width: "35px", height: "35px", color: "rgb(202 186 156)" }} className="svg-inline--fa fa-map-location-dot  mb-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-location-dot" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"></path></svg>
                                <h4 className="text-uppercase m-0">Адрес</h4>
                                <hr className="my-4 mx-auto" />
                                <div className="small text-black-50"><a href="https://maps.app.goo.gl/Hf9oXoCMw1gGKvcS8" target="_blank">ул. Академик Борис Стефанов 35 <br />София, България</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <svg style={{ width: "35px", height: "35px", color: "rgb(202 186 156)" }} className="svg-inline--fa fa-envelope  mb-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"></path></svg>
                                <h4 className="text-uppercase m-0">Имейл</h4>
                                <hr className="my-4 mx-auto" />
                                <div className="small text-black-50"><a href="mailto:info@auracare.bg">info@auracare.bg</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                        <div className="card py-4 h-100">
                            <div className="card-body text-center">
                                <svg style={{ width: "35px", height: "35px", color: "rgb(202 186 156)" }} className="svg-inline--fa fa-mobile-screen-button mb-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="mobile-screen-button" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M0 64C0 28.7 28.7 0 64 0H288c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM208 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM288 64H64V384H288V64z"></path></svg>
                                <h4 className="text-uppercase m-0">Телефон</h4>
                                <hr className="my-4 mx-auto" />
                                <div className="small text-black-50"><a href="tel:+359 899898029">+ 359 899 898
                                    029</a><span>|</span><a href="tel:+359 887401008">+ 359 887 401 008</a></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <section className="contact-area" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="contact-content text-center">
                                <a href="#"><img src="assets/images/logo.svg" alt="logo" /></a>
                                <p>Луксозното Изживяване комбинирано с грижата към себе си</p>
                                <div className="hr"></div>
                                <div className="contact-social">
                                    <ul>
                                        <li> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-3 h-3 mr-1 text-white dark2:text-gray-400 bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                            </path>
                                        </svg><Link to={"/"}>Начало</Link></li>
                                        <li> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-3 h-3 mr-1 text-white dark2:text-gray-400 bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                            </path>
                                        </svg><Link to={"/about"}>За нас</Link></li>
                                        <li> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-3 h-3 mr-1 text-white dark2:text-gray-400 bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                            </path>
                                        </svg><Link onClick={(event) => scrollToAnchor(event, "/", "price-list")}>Ценоразпис</Link></li>
                                        <li> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-3 h-3 mr-1 text-white dark2:text-gray-400 bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                            </path>
                                        </svg><Link to={"/faq"}>ЧЗВ</Link></li>
                                        <li> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-3 h-3 mr-1 text-white dark2:text-gray-400 bi bi-chevron-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                            </path>
                                        </svg><Link to={"/contact"}>Контакти</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </footer>
}

export default Footer;