import React from "react";
import { Link } from "react-router-dom";

function Short({ activeRoute }) {

    return (
        <div class="using-border ">
            <div class="inner_breadcrumb  py-3 ml-4">
                <div className="container-xl mx-md-4 mx-sm-2 mx-xl-auto">
                    <div class="row">
                        <div class="col-lg-8 col-md-7 col-sm-12 wow fadeInUp animated px-0" data-wow-duration="300ms" style={{ visibility: 'visible', 'animation-duration': '300ms', 'animation-name': 'fadeInUp', pointerEvents: 'none' }}>
                            <h1 class="black-text">{activeRoute}</h1>
                        </div>
                        <div class="col-lg-4 col-md-5 content-start  d-none d-sm-flex breadcrumb text-uppercase sm-no-margin-top wow fadeInUp xs-display-none animated" data-wow-duration="600ms" style={{ visibility: 'visible', 'animation-duration': '600ms', 'animation-name': 'fadeInUp' }}>
                            <ul>
                                <li><Link to="/">Начало</Link></li>
                                <li>{activeRoute}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Short;