import logo from './logo.svg';
import './App.css';
import Landing from './Pages/Landing';
import About from './Pages/About';
import FaqPage from './Pages/Faq';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactPage from './Pages/contacts/Contact';
import ScrollToTop from './utils/ScrollToTop';
import ScrollToAnchor from './utils/ScrollToAnchor';
import WhyUS from './components/why-us/WhyUs';

function App() {
  return (
    <div className="App">

      <Router>
        <>
          <ScrollToTop />
          <ScrollToAnchor />
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/why' element={<WhyUS />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/faq' element={<FaqPage />} />
          </Routes>
        </>

      </Router>


    </div >
  );
}

export default App;
