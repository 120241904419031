import React from "react";
import Menu from "../components/menu/Menu";
import Footer from "../components/footer/Footer";
import Short from "../components/short/Short";

const FaqPage = () => {
    return <>
        <Menu />
        <Short activeRoute={'ЧЗВ'} />
        <section className="faq mb-5">
            <div id="faq" className="mt-5 px-2 mb-5">
                <h2 className="u-text u-text-1">Често задаваните въпроси</h2>
                <p className="u-text u-text-2 mb-4">По-долу ще намерите отговори на най-често задаваните въпроси.</p>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                КАКВА Е СЪЩНОСТТА НА ПРОЦЕДУРАТА ПО ИЗБЕЛВАНЕ НА ЗЪБИ
                            </button>
                        </h2>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                Избелването на зъби, посредством нашата система, е напълно безболезнена естетическа процедура, от която може да се възползва всеки, които желае да се сдобие с красива бяла усмивка. Използваме светлината Viollette, която е особено подходяща за хора с чувствителни зъби и венци.

                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                В КАКВО СЕ СЪСТОИ ПРОЦЕДУРАТА
                            </button>
                        </h2>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                При посещение при нас ще ви се направи безплатна консултация с наш специалист, с когото заедно ще определите подходящата продължителност на процедурата, спрямо нивото на белота на вашите естествени зъби.
                                След процедурата зъбите ви ще бъдат с до 7 тона по- бели, в зависимост от вашата естествена белота . Ако желаете още по- силен резултат, който да достигне до 9 тона изсветляване, може да направите допълнителна процедура от 30 мин в същия ден.

                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                ТРЯБВА ЛИ ДА ПРАВЯ ПОДДЪРЖАЩИ ПРОЦЕДУРИ
                            </button>
                        </h2>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">Това зависи изцяло от предпочитанията на клиента и нивото на белота, която желае. За да задържите цвета след първоначално избелване, препоръчваме веднъж месечно да правите поддържаща процедура от 30мин.

                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                КАКВИ ГРИЖИ ТРЯБВА ДА ПОЛАГАМ ЗА ЗЪБИТЕ СИ СЛЕД ПРОЦЕДУРАТА

                            </button>
                        </h2>
                        <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour"
                            data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                След процедурата не се очаква да изпитвате дискомфорт. Оцветяващите храни и напитки като кафе, чай, шоколадови бонбони, могат да доведат до оцветяване на зъбите ви при редовна консумация. Това е валидно не само за избелени зъби, а и за такива, които не са били третирани
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
}
export default FaqPage;