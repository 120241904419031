import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import './contact.css';
import Menu from "../../components/menu/Menu";
import Footer from "../../components/footer/Footer";
import Short from "../../components/short/Short";
const ContactPage = () => {
    const form = useRef();
    const [messageSent, setMessageSent] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [formInput, setFormInput] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleInput = (e) => {
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.value,
        });
    };

    function handleResponse() {
        setIsSuccess(true);
        setTimeout(() => {
            setMessageSent(false);
            setIsSuccess(false);
            setFormInput({
                name: '',
                surname: '',
                email: '',
                phone: '',
                message: '',
            });
        }, 5000);
    }

    function onError() {
        setIsError(true);
        setTimeout(() => {
            setMessageSent(false);
            setIsError(false);
        }, 5000);
    }


    const submitQuestionnaire = async () => {
        setMessageSent(true);
        emailjs
            .sendForm('service_qyz4veu', 'template_do146xj', form.current, {
                publicKey: 'J2yJ--AHjFsUwLqmk',
            })
            .then(
                () => {
                    handleResponse();
                },
                (error) => {
                    onError();
                    
                },
            );


    }

    function handleSubmit(event) {

        event.preventDefault();
        // let validated = validateForm();
        // if (validated) {
        submitQuestionnaire();
        // }
    };

    return <>
        <Menu />
        <Short activeRoute="Контакти" />
        <section id="contact-us" style={{ backgroundImage: "url('assets/images/bg-contact-form-2.jpg')" }}>
            <div className="header container pt-5 pb-3">
                <div className="row justify-content-center">
                    <div className="col-xs-10 col-sm-10 col-md-8">
                        <h2 class="u-text u-text-1">Свържи се с Нас</h2>
                        <p class="u-text u-text-2 mb-4">Попълни формата по-долу и ще отговорим в рамките на деня.</p>
                    </div>
                </div>

            </div>

            {
                messageSent && isError && 
                <div className="sent-error mb-4">
                    <p>Изникна грешка, моля свържете се с нас на посочение телефон в контакти!</p>
                </div>
            }

            {
                messageSent && isSuccess &&
                <div className="sent-success mb-4">
                    <p>Съобщението е пратено успешно!</p>
                </div>
            }

            <div className="wrapper" >

                <div className="inner">

                    <form ref={form} id="regForm">
                        <h3>Свържете се с нас</h3>
                        <div className="form-group">
                            <div className="form-wrapper" data-block-type="FormField">
                                <label for="">Име</label>
                                <input type="text" className="form-control" onChange={handleInput} name="name" data-block-type="FormChild" />
                            </div>
                            <div className="form-wrapper" data-block-type="FormField">
                                <label for="">Фамилия</label>
                                <input type="text" className="form-control" onChange={handleInput} name="surname" data-block-type="FormChild" />
                            </div>
                        </div>
                        <div className="form-wrapper" data-block-type="FormField">
                            <label for="">Телефон</label>
                            <input type="text" className="form-control" onChange={handleInput} name="phone" data-block-type="FormChild" />
                        </div>
                        <div className="form-wrapper" data-block-type="FormField">
                            <label for="">Имейл</label>
                            <input type="text" className="form-control" onChange={handleInput} name="email" data-block-type="FormChild" />
                        </div>
                        <div className="form-wrapper" data-block-type="FormField">
                            <label for="">Съобщение</label>
                            <input type="textarea" className="form-control" onChange={handleInput} name="message" data-block-type="FormChild" />
                        </div>
                        <button onClick={(event) => handleSubmit(event)}>Изпрати</button>
                    </form>
                </div>
            </div>
        </section>
        <Footer />
    </>
}

export default ContactPage;