import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ScrollToAnchor from "../../utils/ScrollToAnchor";

function Menu() {
    const navigate = useNavigate();

    const scrollToAnchor = (event, page, section) => {
        event.preventDefault();
        navigate(page, { state: { section } });
    }
    return (
        <Navbar scrolling collapseOnSelect expand="lg" id="navbar_top">
            <Container fluid="xxl">
                <Navbar.Brand as={Link} to={'/'}>
                    <div id="logo">
                        <img src="assets/images/logo.svg" alt="Logo" />
                    </div>

                </Navbar.Brand>
                <Navbar.Toggle className="x" aria-controls="responsive-navbar-nav">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link as={Link} to={"/"}> Начало</Nav.Link>
                        <Nav.Link as={Link} to={"/about"}> За нас</Nav.Link>


                        <Nav.Link as={Link} to={"/faq"}>ЧЗВ</Nav.Link>

                        <Nav.Link onClick={(event) => scrollToAnchor(event, "/", "price-list")}>Ценоразспис</Nav.Link>
                        <Nav.Link as={Link} to={"/contact"}>
                            Контакти
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Menu;