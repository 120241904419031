import React, { useEffect } from "react";
import Menu from "../components/menu/Menu"
import Footer from "../components/footer/Footer"
import PriceList from "../components/price-list/PriceList";
import WhyUS from "../components/why-us/WhyUs";
function Landing() {

    return <>
        <Menu />
        <div className="main-top" id="home">
            <div className="main-banner">
                <div className="container-xl">
                    <div className="row justify-content-md-end justify-content-sm-start justify-content-xs-start  ">
                        <div className="col-xs-10 col-md-8 align-self-end">
                            <div className="style-banner">
                                <h2>Луксозно<span>Изживяване</span></h2>
                                <h3>комбинирано<span>с грижата</span></h3>
                                <h4>към себе си</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="intro">
            <div className="intro-content pb-5 mb-5">
                <div className="row justify-content-center gx-0" >
                    <div className="col-md-8 col-sm-12 col-xs-12 text-middle  align-self-middle intro-item-2  py-lg-3 py-md-4 py-sm-4 py-3">

                        <h3 className="pt-5 pb-2">La Aura </h3>
                        <p>Добре дошли в La Aura - вашето място за перфектна усмивка,  където красотата и светлината на вашата "аура" се сливат в едно.
                            Решението за красива белота на зъбите, постигната по здравословен начин, е вече и в България. Прекарахме години в трупане на опит в цяла Европа от доволни клиенти, защото ние сме там, където се крият много красиви усмивки, които не трябва да останат незабелязани.

                        </p>

                    </div>

                </div>
            </div>
            <div className="row g-0" >
                <div className="col-12 text-middle intro-item-3">
                    <div className="row g-0" >
                        <div className="col-md-6  col-sm-6 col-xs-12 text-middle  intro-item-2 item-img ">
                            <img src="/assets/images/10.png" className="img-fluid" />
                        </div>

                        <div className="col-md-6 col-sm-6 col-xs-12  intro-item-1  py-lg-4 py-md-3 py-sm-3 py-3" data-aos="fade-left">
                            <h3 className=" px-lg-4 px-md-3 px-sm-2 px-3">Здравословното </h3>
                            <h4 className=" px-lg-4 px-md-3 px-sm-2 px-3">решение </h4>
                            <h5 className=" px-lg-4 px-md-3 px-sm-2 px-3">за бели зъби</h5>
                        </div>

                    </div>
                </div>
                <div className="col-12  text-middle  intro-item-5 ">
                    <div className="row g-0" >
                        <div className="col-md-6 col-sm-6 col-xs-12 justify-content-center text-center intro-item-1 order-2 order-xs-2 order-sm-1 order-md-1  py-lg-4 py-md-3 py-sm-3 py-3" data-aos="fade-up">
                            <h3 className="px-lg-4 px-md-3 px-sm-3 px-3">Траен резултат </h3>
                            <h4 className="px-lg-4 px-md-3 px-sm-3 px-3">на достъпна</h4>
                            <h5 className="px-lg-4 px-md-3 px-sm-3 px-3">цена</h5>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12 intro-item-2 item-img order-1 order-xs-1 order-sm-2 order-md-2">
                            <img src="/assets/images/7.png" className="img-fluid" />
                        </div>
                    </div>

                </div>

            </div>
        </section>
        <section className="focus-point py-lg-4 py-md-3 py-sm-3 py-3" id="focus-point">
            <div className="container mt-4">
                <div className="row justify-content-center">
                    <div className="focus-point-item col-xs-4 col-sm-6 col-md-3 col-lg-3 align-self-end px-sm-3 px-5 px-md-1" data-aos="fade-up" >
                        <div>
                            <svg className="cc-icon cc-icon--star" aria-hidden="true" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style={{ width: "40px", height: "40px" }}><path d="M8 1c.21 0 .4.13.47.33l1.62 4.54 4.92.1a.5.5 0 01.29.9l-3.92 2.9 1.42 4.58a.5.5 0 01-.75.56L8 12.17 3.95 14.9a.5.5 0 01-.75-.56l1.42-4.58L.7 6.87a.5.5 0 01.29-.9l4.92-.1 1.62-4.54A.5.5 0 018 1zm0 2L6.74 6.52a.5.5 0 01-.46.33l-3.8.08L5.5 9.17a.5.5 0 01.18.55l-1.1 3.56 3.14-2.13a.5.5 0 01.56 0l3.15 2.13-1.1-3.56a.5.5 0 01.17-.55l3.02-2.23-3.8-.08a.5.5 0 01-.46-.33L8 2.99z"></path></svg>
                        </div>
                        <div className="focus-point-item-text"><p className="text-right mt-lg-5 mt-md-4 mt-sm-4 mt-3">Водещи в индустрията технологии</p></div>
                    </div>
                    <div className="focus-point-item col-xs-4 col-sm-6 col-md-3 col-lg-3 align-self-end px-sm-5 px-5 px-md-1" data-aos="fade-up" data-aos-duration="1500">
                        <div>
                            <svg className="cc-icon cc-icon--check_mark" aria-hidden="true" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style={{ width: "40px", height: "40px" }}><path fillRule="evenodd" d="M14.441 3.733a.5.5 0 010 .707l-7.94 7.94a.5.5 0 01-.707 0L2.52 9.105a.5.5 0 11.707-.707l2.922 2.922 7.586-7.587a.5.5 0 01.707 0z" clipRule="evenodd"></path></svg>
                        </div>
                        <div className="focus-point-item-text"><p className="text-right mt-lg-5 mt-md-4 mt-sm-4 mt-3">Европейски признати акредитации</p></div>
                    </div>
                    <div className="focus-point-item col-xs-4 col-sm-6 col-md-3 col-lg-3 align-self-end px-sm-5 px-xs-5 px-md-1" data-aos="fade-up" data-aos-duration="2000">
                        <div>
                            <svg className="cc-icon cc-icon--leaf" aria-hidden="true" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style={{ width: "40px", height: "40px" }}><path d="M14.63.98a1 1 0 00-.78-.3h-.03l-.07.01-.25.03a41.89 41.89 0 00-3.87.52c-1.1.2-2.31.48-3.38.83a7.15 7.15 0 00-2.74 1.48 6.04 6.04 0 00-.36 8.15L1.53 13.3a.5.5 0 00.71.7l1.61-1.6a6.04 6.04 0 008.2-.31 7.15 7.15 0 001.48-2.74c.36-1.07.63-2.27.83-3.39a46.92 46.92 0 00.53-3.86l.02-.26.01-.07v-.02a1 1 0 00-.29-.78zm-1.72.8l.17-.02-4.89 4.9v-4.1c1.76-.41 3.61-.66 4.72-.78zM8.45 7.81h4.5c-.21.8-.45 1.56-.74 2.2H6.24l2.21-2.2zM7.7 2.68v4.46L5.5 9.35V3.43c.64-.3 1.4-.54 2.2-.75zM4.22 4.26c.2-.2.47-.4.78-.57v6.15L3.86 11a5.04 5.04 0 01.36-6.73zm.34 7.44l1.19-1.18h6.22c-.2.35-.4.65-.62.87a5.04 5.04 0 01-6.8.31zm8.5-4.4h-4.1l4.9-4.9-.03.3a41.1 41.1 0 01-.76 4.6z"></path></svg>
                        </div>
                        <div className="focus-point-item-text"><p className="text-right mt-lg-5 mt-md-4 mt-sm-4 mt-3">Етичен, безопасен подход към естествените резултати</p></div>
                    </div>
                    <div className="focus-point-item col-xs-4 col-sm-6 col-md-3 col-lg-3 align-self-end px-sm-5 px-5 px-md-1" data-aos="fade-up" data-aos-duration="2100">
                        <div>
                            <svg className="cc-icon cc-icon--heart" aria-hidden="true" focusable="false" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style={{ width: "40px", height: "40px" }}><path d="M8.86 4.38a2.78 2.78 0 013.72-.3c1.4 1.2 1.2 3.11.19 4.13L7.98 13.1c-.05.06-.1.06-.19 0L3.01 8.2a2.8 2.8 0 01.19-4.1c1.06-.9 2.7-.76 3.74.28l.96.98.96-.98zm-.96-.45l.24-.25a3.78 3.78 0 015.07-.38l.01.01v.01a3.82 3.82 0 01.26 5.59l-4.79 4.9a1.12 1.12 0 01-1.45.12l-.1-.06L2.3 8.91a3.8 3.8 0 01.26-5.57 3.79 3.79 0 015.1.33l.01.01.24.25z" fillRule="evenodd"></path></svg>
                        </div>
                        <div className="focus-point-item-text"><p className="text-right mt-lg-5 mt-md-4 mt-sm-4 mt-3">Качеството е на първо място във всико, което правим</p></div>
                    </div>
                </div>

            </div>
        </section>
        <WhyUS />

        <section className="collection py-lg-4 py-md-3 py-sm-3 py-3">
            <div className="container py-lg-5 py-md-4 py-sm-4 py-3">

                <div className="row my-lg-5 my-md-4 my-3">
                    <div className="col-12">
                        <h3 className="title mb-lg-5 mb-md-4 mb-sm-4 mb-3">Иновативно <span style={{textTransform: 'uppercase'}}>избелване</span></h3>
                    </div>
                    {/* <div className="col-lg-5 col-md-6">
                        <img src="/assets/images/c2.jpg" alt="news image" className="img-fluid" />
                    </div> 
                    {/* <div className="col-lg-7 col-md-6 collection-w3layouts">
                        {/* <h4>Individual Collection</h4> 
                        <p className="mt-2">"La AURA" не е просто кабинет за избелване на зъби, а място, където се
                            грижим и за вашата &quot;аура&quot; - тази невидима, но изключително важна
                            енергия, която дефинира вашата уникалност и привлича вниманието на
                            околните. Това е изживяване, предназначено да преобрази усмивките и
                            да повиши увереността. Когато стъпите в елегантното, модерно
                            пространство на La Aura, вие сте посрещнати от усещане за спокойствие
                            и изтънченост.</p>

                         <div className="view-buttn mt-lg-4 mt-3">
                            <a href="single.html" className="">Read More</a>
                        </div> 
                    </div> */}
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-6">
                        {/* <h4>Иновативна технология </h4> */}
                        <p className="mt-4 pb-4 align-middle">La Aura използва последно поколение ултравиолетова безконтактна
                            технология, която предотвратява усещането за дискомфорт по време и
                            след процедурата и позволява да се насладите на директен резултат
                            след нея.</p>

                        {/* <div className="view-buttn mt-lg-4 mt-3">
                            <a href="single.html" className="">Read More</a>
                        </div> */}
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <img src="/assets/images/clinic2-img.jpg" alt="news image" className="img-fluid" />
                    </div>
                </div>



                {/* <div className="row">
                    <div className="col-lg-7 col-md-6 collection-w3layouts">
                        <h4>Unique Collection</h4>
                        <p className="mt-2">sed do eiusmod tempor incididunt ut Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, eiusmod tempor incididunt ut labore et consectetur adipiscing sed do eiusmod</p>
                        <div className="view-buttn mt-lg-4 mt-3">
                            <a href="single.html" className=" scroll">Read More</a>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 about-fashion-one">
                                <img src="/assets/images/c3.jpg" alt="news image" className="img-fluid" />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 about-fashion-two mt-lg-5 mt-md-4 mt-sm-3">
                                <img src="/assets/images/ab2.jpg" alt="news image" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
        </section>

        <section className="bleaching" id="bleaching">
            <div className="container gx-0">
                <div className="row justify-content-center mt-5">
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 align-self-end">
                        <h3 className="title text-right mt-lg-5 mt-md-4 mt-sm-4 mt-3">Иновация в</h3>
                        <h5 className="title text-right mt-lg-2 mt-md-2 mt-sm-2 mt-2 mb-lg-2 mb-md-2 mb-sm-2 mb-2">избелването</h5>
                        <h4 className="title text-right mb-lg-4 mb-md-4 mb-sm-4 mb-4"> на зъби</h4>
                    </div>
                </div>
                <div className="row justify-content-center mt-5 gx-0" style={{ backgroundColor: '#caba9c',color: 'white' }}>
                    <div className="col-8 py-3">
                        <p className="mt-2">"La AURA" не е просто кабинет за избелване на зъби. Това е изживяване, предназначено да преобрази усмивките и
                            да повиши увереността. Когато стъпите в елегантното, модерно
                            пространство на La Aura, вие сте посрещнати от усещане за спокойствие
                            и изтънченост.</p>
                    </div>
                </div>

            </div>
        </section>

        <section id="price-list">
            <PriceList />
        </section>

        {/* <section className="advertise-count">   
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 adv-text">
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 counter-number">
                        <div className="fashion-collet-txt">
                            <h5 className="text-center">Best Fashion Collection </h5>
                            <h6 className="text-center mt-lg-4 mt-3"> Get 55%/off</h6>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

        {/* <section className="service py-lg-4 py-md-3 py-sm-3 py-3" id="service">
            <div className="container py-lg-5 py-md-4 py-sm-4 py-3">
                <h3 className="title text-center mb-lg-5 mb-md-4 mb-sm-4 mb-3"># Our <span>Services</span></h3>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 service-grid-wthree text-center">
                        <div className="ser-fashion-grid">
                            <div className="about-icon mb-md-4 mb-3">
                                <span className="fa fa-ravelry" aria-hidden="true"></span>
                            </div>
                            <div className="ser-sevice-grid">
                                <h4 className="pb-3">Tailoring </h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 service-grid-wthree text-center">
                        <div className="ser-fashion-grid">
                            <div className="about-icon mb-md-4 mb-3">
                                <span className="fa fa-female" aria-hidden="true"></span>
                            </div>
                            <div className="ser-sevice-grid">
                                <h4 className="pb-3">Fashion</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 service-grid-wthree text-center">
                        <div className="ser-fashion-grid">
                            <div className="about-icon mb-md-4 mb-3">
                                <span className="fa fa-area-chart" aria-hidden="true"></span>
                            </div>
                            <div className="ser-sevice-grid">
                                <h4 className="pb-3">Embroidery</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mt-lg-4 mt-3 service-grid-wthree text-center">
                        <div className="ser-fashion-grid">
                            <div className="about-icon mb-md-4 mb-3">
                                <span className="fa fa-magic" aria-hidden="true"></span>
                            </div>
                            <div className="ser-sevice-grid">
                                <h4 className="pb-3">Ironing</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mt-lg-4 mt-3 service-grid-wthree text-center">
                        <div className="ser-fashion-grid">
                            <div className="about-icon mb-md-4 mb-3">
                                <span className="fa fa-american-sign-language-interpreting" aria-hidden="true"></span>
                            </div>
                            <div className="ser-sevice-grid">
                                <h4 className="pb-3">Stitch</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 mt-lg-4 mt-3 service-grid-wthree text-center">
                        <div className="ser-fashion-grid">
                            <div className="about-icon mb-md-4 mb-3">
                                <span className="fa fa-diamond" aria-hidden="true"></span>
                            </div>
                            <div className="ser-sevice-grid">
                                <h4 className="pb-3">Sewing</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <Footer />

    </>
}

export default Landing;