import React from "react";
import './whyus.css';
export default function WhyUS() {

    return (
        <>
            <section id="why_us">
                <div className="container">
                    <div className="row gx-0 text-center ">
                        <div className="col-lg-3 col-md-4  order-3 order-3 order-sm-3 order-md-3">
                            <div className="row gx-0">
                                <div className="col-lg-12 col-sm-6 col-md-12 item-1">
                                    <h6>Уникален подход</h6>
                                    <p>Нашият дентален екип отделя време, за да разбере вашите специфични изисквания и да приспособи лечението за избелване на зъбите според тях, за да постигнем желаните от вас резултати. </p>

                                </div>
                                <div className="col-lg-12 col-sm-6 col-md-12 item-2">
                                    <h6>Безопасно и ефективно лечение</h6>
                                    <p>Нашите процедури са предназначени да сведат до минимум чувствителността на зъбите и да увеличат максимално резултата от избелването, давайки ви по-ярка усмивка, без да засягаме здравето на вашите зъби и венци.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 item-img order-2 order-2 order-sm-2 order-md-2" >
                            {/* <img src="assets/images/smile1.jpg" style={{ width: "100%" }} /> */}
                        </div>
                        <div className="col-lg-5 col-md-12 order-1 order-1 order-sm-1 order-md-1 ">
                            <div className="row gx-0">
                                <div className="col-lg-12 item-5">
                                    <h3 class=" px-lg-4 px-md-3 px-sm-2 px-3">Защо </h3>
                                    <h4 class=" px-lg-4 px-md-3 px-sm-2 px-3">клиента </h4>
                                    <h5 class=" px-lg-4 px-md-3 px-sm-2 px-3">предпочита нас</h5>
                                </div>
                                <div className="col-lg-12">
                                    <div className="row gx-0">
                                        <div className="col-md-12 col-12 item-3">
                                            <h6>Комфорт и удовлетворение на пациента</h6>
                                            <p>Вашият комфорт и удовлетворение са нашите основни приоритети. Нашият екип ще Ви предостави персонализирани грижи, за да гарантираме положително и възнаграждаващо изживяване.</p>
                                        </div>
                                        {/* <div className="col-md-6 col-12 item-4">we utilize proven whitening techniques and high-quality whitening agents to ensure safe and effective results.</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}